






import Vue from 'vue';
import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';

export default Vue.extend({
  name: 'ResetPassword',
  components: {},
  inject: [],
  mixins: [],
  props: {},
  data () {
    return {};
  },
  computed: {},
  watch: {},
  // Lifecycle Hooks
  // beforeCreate () {},
  // created () {},
  // beforeMount () {},
  // mounted () {},
  // beforeUpdate () {},
  // updated () {},
  // beforeDestroy () {},
  // destroyed () {},
  // Methods
  methods: {},
});
